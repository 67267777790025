<template>
    <div class="hello">
      <p>about</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'HelloWorld',
   
  }
  </script>
  
