import { createWebHistory, createRouter } from "vue-router";
import Woodlands from "../views/Woodlands.vue";
import About from "../views/AboutInfo.vue";
// import categories from "../views/categories.vue";
const routes = [
    {
        path: "/",
        name: "Woodlands",
        component: Woodlands,
    },
    {
        path: "/About",
        name: "About",
        component: About,
    },
    // {
    //     path: '/categories/:url',
    //     name: 'categories',
    //     component: categories
    // },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;