<template>
        <div class="container">
            <div class="container-fluid">
        <div class="row" style=" justify-content:center; display:flex; align-items:center;">

            <div class="team" style="justify-content: center; display: flex; flex-wrap: wrap; align-items: center; ">



                <div v-for="categ in categories" :key="categ" class=" mt-0.5" style="width: 115px;height:155px">
                    <div class="member d-flex align-items-start" style="height: 150px; width: 112px;" v-on:click="categoriesFunction(categ.name)">


                        <a style="cursor: pointer;">

                            <div class="pic">
                                <img src="" class="img-fluid" alt="" style="height:90px; width:90px">

                                <p class="block-ellipsis">{{categ.name}}</p>


                            </div>
                        </a>
                    </div>
                </div>


            </div>
        </div>
    </div>
            <button v-on:click="nextpage()">Click</button>

</div>
  </template>
  
  <script>

        import axios from 'axios'

  export default {
    name: 'HelloWorld',
    data() {
            return {
                // for new tasks
                loginCheck: false,
                name: '',
                mobile: '',
                banners: [],
                categories: [],
            };
        },

    mounted() {

        this.getmethodColumn();
        this.getmethodDishes();
        },
        methods: {
            getImage(imagePath) {
                return 'https://demo2.areteonline.in/Img/Bann/'+imagePath;
            },
            nextpage(){
                this.$router.push({ name: 'About' })
            },
            getmethodColumn() {
                let that=this;


                var optionAxios = {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Content-type': 'application/json',
                    }                           }
                // Make a request for a user with a given ID
                        axios.get('http://woo.aretegroup.in/api/banners',optionAxios)
                        .then(function (response) {
                            // handle success
                           // alert()
                            console.log(response.data);
                            that.banners=response.data;
                        })
                        .catch(function (error) {
                            // handle error
                            console.log(error);
                        })
                        .then(function () {
                            // always executed
                            console.log('---------')
                        });

            },
            getmethodDishes() {
                let that=this;
                var optionAxios = {
                                headers: {
                                    'Content-Type': 'application/x-www-form-urlencoded'
                                }
                            }
                axios.get('http://woo.aretegroup.in/api/Categs',optionAxios)
                    .then((res) => {
                        console.log(res.data)
                        that.categories = res.data;
                    })
                    .catch((error) => {
                        console.error(error)
                    })
                    .then(function () {
                            // always executed
                            console.log('---------')
                        });
            },


        }

  }
  </script>
  
  