<template>

  <div id="app">

      <router-view />
  </div>
</template>

<script>

  export default {
      name: 'App',
      components: {

      }
  }
</script>

<style scoped>
  #app {
      font-family: Avenir, Helvetica, Arial, sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-align: center;
      color: #2c3e50;
      margin-top: 60px;
  }

  body {
      background-color: #e9ecef;
  }
</style>
