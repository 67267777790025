import { createApp } from 'vue'
import App from './App.vue'
import router from './router' // <---
import BootstrapVue3 from 'bootstrap-vue-3'

//import adminlte styles
import 'admin-lte/dist/css/adminlte.min.css'


//createApp(App).use(router).mount('#app')
createApp(App).use(router).use(BootstrapVue3).mount('#app')
